import QrCode from "qrcode";

export default {
  props: {
    // 二维码内容
    content: {
      type: String,
      required: false,
    },
    // LOGO图
    logoUrl: {
      type: String,
      required: false,
    },
    // 默认50
    logoWidth: {
      type: Number,
      required: false,
      default: 50,
    },
    logoHeight: {
      type: Number,
      required: false,
      default: 50,
    },
    // 二维码的宽度
    qrCodeWidth: {
      type: Number,
      required: false,
      default: 244,
    },
    // 二维码的高度
    qrCodeHeight: {
      type: Number,
      required: false,
      default: 244,
    },
    // 二维码属性
    opts: {},
  },
  data() {
    return {
      qrCodeContent: "",
      qrCodeImage: "",
      canvasId: "qr-code-image-box",
      loading: false,
      defaultOpts: {
        errorCorrectionLevel: "M",
        type: "image/jpeg",
        rendererOpts: {
          quality: 0.3,
        },
      },
    };
  },
  mounted() {
    Object.assign(this.defaultOpts, this.opts);
    this.qrCodeContent = this.content;
  },
  methods: {
    /**
     * 清除二维码
     */
    clearQrCode() {
      this.qrCodeContent = "";
      this.qrCodeImage = "";
    },

    /**
     * 生成二维码
     * @param {*} qrContent  内容
     * @param {*} success 成功
     * @param {*} fail 失败
     * @returns
     */
    toQrCode(qrContent, success, fail) {
      //  没有内容
      if (!qrContent || qrContent.length === 0) {
        return false;
      }
      this.qrCodeContent = qrContent;
      const that = this;
      this.loading = true;
      QrCode.toDataURL(
        this.qrCodeContent,
        this.defaultOpts,
        (err, base64ImageData) => {
          that.loading = false;
          if (err) {
            // canvas生成失败
            return fail && fail({ code: 2 });
          }
          if (base64ImageData) {
            that.qrCodeImage = base64ImageData;
            return success && success(that.qrCodeImage);
          } else {
            // 未生成图片
            return fail && fail({ code: 3 });
          }
        }
      );
    },
  },
  watch: {
    content: {
      immediate: true,
      handler(val) {
        this.qrCodeContent = val;
      },
    },
  },
};
