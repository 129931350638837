import fa from "element-ui/src/locale/lang/fa";

export default {
    /*
     *  本地保存keys
     * */
    storageKeys: {
        /*
         * 主页头部 选中的index
         * */
        HOME_HEAD_SELECT_INDEX: "HOME_HEAD_SELECT_INDEX",

        /*
         * 公文，当前选择的tab
         * */
        DOCUMENT_SELECT_TAB: "DOCUMENT_SELECT_TAB",

        /*
         * 个人中心,当前选中index
         * */
        MINE_MEMU_CURRENT_INDEX: "MINE_MEMU_CURRENT_INDEX",

        /*
         *
         * 当前选择的岗位 - obejct
         * */
        SELECT_CURRENT_JOB: "SELECT_CURRENT_JOB",

        /**
         * 当前选择的计划
         */
        SELECT_CURRENT_PLAN_OBJECT: "SELECT_CURRENT_PLAN_OBJECT",

        /**
         * 选择当前的验证类型
         */
        SELECT_SIGN_TYPE: "SELECT_SIGN_TYPE",
    },
    /**
     *  存放数据到storemanger
     *
     *  key: 存放key  string
     *  value: 存放的值   可以是string 也可以是json
     */
    saveDataToLocalStorage: function(key, value) {
        if (key == null || key.length == 0) return;
        if (value == null) return;

        if (typeof value !== "string") {
            value = JSON.stringify(value);
        }
        localStorage.setItem(key, value);
    },

    /**
     * 从storemanger取出数据 - 字符串
     *
     *  key: 存放key  string
     */
    getDataWithLocalStorage: function(key) {
        if (key == null || key.length == 0) return;
        return localStorage.getItem(key);
    },
    /**
     * 从storemanger取出数据  - Object
     * @param key
     * @returns {string}
     */
    getObjectWithLocalStorage: function(key) {
        if (key == null || key.length == 0) return null;
        const str = this.getDataWithLocalStorage(key);
        if (str != null && str.length != 0) {
            const item = JSON.parse(str);
            return item;
        } else {
            return null;
        }
    },

    /**
     *  移除key
     */
    removeDataWithLocalStorage: function(key) {
        if (key == null || key.length == 0) return;
        localStorage.removeItem(key);
    },

    /**
     * 移除所有数据
     */
    removeAllDataWithLocalStorage() {
        for (let item in this.storageKeys) {
            this.removeDataWithLocalStorage(item);
        }
    },
    /**
     * 获取所有的key
     */
    getStorageAllKeys() {
        // 所有
        const keyList = [];
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            keyList.push(key);
        }
        return keyList;
    },
    /**
     * 是否存在某个key       // 移除 offlineid lzb 2020-06-25 socket版本
     */
    isExitKey(KeyWord) {
        const keyList = this.getStorageAllKeys();
        if (!keyList || keyList.length == 0) {
            return false;
        }
        if (!KeyWord || KeyWord.length == 0) {
            return false;
        }

        for (let i = 0; i < keyList.length; i++) {
            const sourceKey = keyList[i];
            if (sourceKey.indexOf(KeyWord)) {
                return true;
            }
        }
        return false;
    },
    /**
     * 移除包含某个key的数据       // 移除 offlineid lzb 2020-06-25 socket版本
     */
    removeContainerOfKeyWord(KeyWord) {
        const keyList = this.getStorageAllKeys();
        if (!keyList || keyList.length == 0) {
            return false;
        }
        if (!KeyWord || KeyWord.length == 0) {
            return false;
        }

        for (let i = 0; i < keyList.length; i++) {
            const sourceKey = keyList[i];
            if (sourceKey.indexOf(KeyWord) != -1) {
                this.removeDataWithLocalStorage(sourceKey);
            }
        }
        return false;
    },
    /**
     * 移除包含某个key的数据  lzb 20240325
     * @param {} key
     * @returns
     */
    removeContainerOfKey(key) {
        const keyList = this.getStorageAllKeys();
        if (!keyList || keyList.length == 0) {
            return false;
        }
        if (!key || key.length == 0) {
            return false;
        }
        for (let i = 0; i < keyList.length; i++) {
            const sourceKey = keyList[i];
            if (sourceKey.includes(key)) {
                this.removeDataWithLocalStorage(sourceKey);
            }
        }
    },
};
