var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "qr-code-wrapper",
      style: {
        width: _vm.qrCodeWidth + "px",
        height: _vm.qrCodeHeight + "px",
        lineHeight: _vm.qrCodeHeight + "px"
      }
    },
    [
      _vm.qrCodeImage
        ? _c("div", { staticClass: "has-qr-code-image-box" }, [
            _c("img", {
              staticClass: "qr-code-image",
              attrs: { src: _vm.qrCodeImage }
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.logoUrl,
                  expression: "logoUrl"
                }
              ],
              staticClass: "qr-code-logo",
              style: {
                width: _vm.logoWidth + "px",
                height: _vm.logoHeight + "px"
              },
              attrs: { src: _vm.logoUrl }
            })
          ])
        : _c("div", { staticClass: "no-qr-code-image-box" }, [
            _vm._v(" 暂无二维码 ")
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }