/*
 * 存储开始的item.offlineRecordId到本地
 * */

import StoreDBManger from "../StoreDBManger";
import { getCurrentUserId } from "../user/UserManger";

const OFFLINE_KEY = "offlineRecordId";
/**
 *  保存培训记录的id
 */
export const setOfflineRecordIdToDB = (offlineRecordId) => {
    if (offlineRecordId && offlineRecordId.length > 0) {
        removeOfflineRecordIdToDB();
        // 培训记录key
        const offlineRecordIdKey = getCurrentUserId() + OFFLINE_KEY;
        localStorage.setItem(offlineRecordIdKey, offlineRecordId);
    }
};
/**
 * 移除培训记录的id
 */
export const removeOfflineRecordIdToDB = () => {
    StoreDBManger.removeContainerOfKeyWord(OFFLINE_KEY);
};

/**
 * 获取培训记录的id
 */
export const getOfflineRecordIdFromDB = () => {
    // 培训记录key
    const offlineRecordIdKey = getCurrentUserId() + OFFLINE_KEY;
    const offlineRecordId = localStorage.getItem(offlineRecordIdKey);
    return offlineRecordId;
};
